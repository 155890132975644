.faq-section {
  padding-top: 100px;
  padding-bottom: 80px;
  background: @tq-neutral-400;

  @media @screen767 {
    padding-top: 60px;
    padding-bottom: 40px;
  }

  .ant-collapse>.ant-collapse-item>.ant-collapse-header {
    padding: 16px;
  }

  .faq-section-title {
    .mb-32;

    @media @screen767 {
      .mb-16;
    }

    .title {
      .display-h2;
      .mb-8;

      @media @screen767 {
        .display-h4;
      }
    }

    .subtitle {
      .paragraph-l-xl;

      @media @screen767 {
        .paragraph-l;
      }
    }
  }

  .ant-collapse {
    background: @tq-neutral-400;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    .ant-collapse-item {
      width: 100%;
      margin-bottom: 20px;
      display: inline-block;


      @media @screen767 {
        width: 100%;
        margin-bottom: 12px;
      }
    }
  }


}