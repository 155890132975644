/*collection card css start here*/
.collection-card {
  max-width: 200px;
  min-width: 200px;

  &.collection-md {
    max-width: 160px;
    min-width: 160px;

    .collection-img {
      width: 100%;
      padding-top: 100%;
      border-radius: 8px;
      overflow: hidden;
    }
  }

  &.collection-lg {
    max-width: 160px;
    min-width: 160px;

    .collection-img {
      width: 100%;
      padding-top: 130%;
      border-radius: 8px;
      overflow: hidden;
    }
  }

  &.collection-list {
    max-width: 343px;
    width: 100%;
    .d-flex;

    .collection-img {
      width: 24.108%;
      max-width: 324px;
      padding-top: 16.587%;
      height: 100%;

      @media @screen767 {
        border-radius: 4px;
        width: 37.32%;
        padding-top: 27.99%;
      }
    }

    .collection-dec {
      .flex-1;
      .mt-0;
      padding-left: 12px;
    }
  }

  .collection-img {
    width: 100%;
    padding-top: 74.078%;
    border-radius: 8px;
    overflow: hidden;
    display: block;

    @media @screen767 {
      padding-top: 74.63%;
    }

    img {
      transform: scale(1);
      -webkit-transition: all 0.4s ease-in-out;
      -moz-transition: all 0.4s ease-in-out;
      -o-transition: all 0.4s ease-in-out;
      transition: all 0.4s ease-in-out;
    }

    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }

  .collection-dec {
    padding-top: 12px;

    @media @screen767 {
      padding-top: 8px;
    }

    .title {
      .text-md-bold;

      @media @screen767 {
        .text-small-bold;
      }
    }
  }
}

.collection-carousel {
  .w-full;

  .slick-slide {
    margin: 0 6px;
  }

  .slick-slide {
    width: calc(calc(100vw - 175px) / 6.1);

    @media @screen1450 {
      width: calc(calc(100vw - 175px) / 5.1);
    }

    @media @screen1150 {
      width: calc(calc(100vw - 140px) / 4.1);
    }

    @media @screen1024 {
      width: calc(calc(100vw - 130px) / 3.1);
    }

    @media @screen767 {
      width: calc(calc(100vw - 39px) / 3.1);
    }

    @media @screen540 {
      width: calc(calc(100vw - 39px) / 2.1);
    }
  }

  .collection-card {
    max-width: 100%;
    min-width: 100%;
  }
}

.collection-list-section {
  &.separatoroff {
    .section-header {
      box-shadow: unset;
    }

    .collection-list-item {
      border: 0;
    }
  }

  .section-header {
    box-shadow: @tq-shadow-bottom;
    padding: 16px 0;
    margin-bottom: 16px;

    @media @screen767 {
      padding: 16px;
      margin: 0;
    }

    .ant-typography {
      margin: 0;
    }
  }

  .collection-card.collection-list {
    max-width: 100%;
  }

  .collection-list-item {
    height: 100%;
    padding: 0 0 20px 0;
    margin-bottom: 20px;
    border-bottom: 1px solid @tq-border-bottom;

    @media @screen767 {
      padding: 16px;
      margin-bottom: 0;
    }

    .collection-dec {
      display: flex;
      flex-direction: column;
      gap: 4px;

      * {
        margin: 0;
      }

      .title {
        font: @tq-text-lg-bold;

        @media @screen767 {
          font: @tq-text-md-bold;
        }
      }

      .collection-dec {
        font: @tq-paragraph-m;

        @media @screen767 {
          font: @tq-text-small;
        }
      }

      .video-count {
        font: @tq-text-small-kicker;

        @media @screen767 {
          font: @tq-text-tiny-kicker;
          letter-spacing: 0.1em;
        }
      }
    }
  }
}

.collection-grid {
  .w-full;

  .collection-card {
    max-width: 100%;
    min-width: 100%;

    .collection-dec {
      .title {
        margin-bottom: 6px;

        @media @screen767 {
          margin-bottom: 4px;
        }
      }

      .collection-dec {
        .text-small;
      }
    }
  }
}

/*collection card css end here*/

/*collection hero css start here*/
.collection-hero {
  padding: 40px 0;

  @media @screen767 {
    padding-top: 32px;
    padding-bottom: 24px;
  }

  .collection-hero-wrapper {
    display: flex;
    align-items: flex-end;
    gap: 32px;

    @media @screen767 {
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 24px;
    }

    .collection-hero-img {
      height: 248px;
      width: 248px;
      border-radius: 8px;
      overflow: hidden;
    }

    .collection-hero-description {
      .collection-hero-title {
        font: @tq-display-h1;
        letter-spacing: 0.02em;

        @media @screen767 {
          font: @tq-display-h3;
          letter-spacing: 0.01em;
        }
      }

      p {
        font: @tq-text-lg;

        @media @screen767 {
          font: @tq-text-md;
        }
      }
    }
  }
}

/*collection here css end here*/
