@import url('../variable.less');

/*video card css start here*/
.video-grid {
  .video-card {
    max-width: 100%;
    min-width: unset;

    .video-image {
      padding-top: 56.177%;

      @media @screen767 {
        border-radius: 8px;
      }
    }

    .vc-description {
      span {
        letter-spacing: 0.02em;

        @media @screen767 {
          .text-small-bold;
        }
      }
    }
  }
}

.video-select {
  @media @screen767 {
    display: flex;
    align-items: center;
    margin-left: 12px;
  }
}

.video-list-grid {
  .section-header {
    padding-bottom: 16px;
    box-shadow: @tq-shadow-bottom;
    margin-bottom: 4px;
  }

  .video-list-item {
    .p-16;
    box-shadow: @tq-shadow-bottom;
    padding: 16px;
    height: 100%;

    .video-card.videolist {
      max-width: 100%;
    }
  }

  &.separatoroff {
    .video-card.videolist,
    .section-header {
      box-shadow: unset;
    }
  }
}

.video-carousel {
  width: 100%;
  display: block;

  .slick-slide {
    margin: 0 8px 0 8px;

    @media @screen767 {
      margin: 0 4px;
    }
  }

  .slick-slide {
    width: calc(calc(100vw - 144px) / 5.1);

    @media @screen1450 {
      width: calc(calc(100vw - 144px) / 4.1);
    }

    @media @screen1150 {
      width: calc(calc(100vw - 105px) / 3.2);
    }

    @media @screen1024 {
      width: calc(calc(100vw - 105px) / 3);
    }

    @media @screen767 {
      width: calc(calc(100vw - 36px) / 2.1);
    }
  }

  .video-card {
    min-width: 100%;
    max-width: 100%;
    width: 100%;
  }

  .slick-track {
    .d-flex;
  }
}

.video-card {
  min-width: 316px;
  max-width: 316px;
  width: 100%;

  @media @screen991 {
    min-width: 352px;
    max-width: 352px;
  }

  @media @screen767 {
    min-width: 160px;
    max-width: 160px;
    width: 100%;
  }

  .vc-description {
    .pt-12;
    .gap-8;
    display: inline-flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;

    @media @screen767 {
      .pt-8;
      .gap-6;
    }
  }

  &.videolist {
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    box-shadow: @tq-shadow-bottom;

    @media @screen767 {
      padding: 16px;
    }

    .video-image {
      width: 31.035%;
      padding-top: 17.433%;
      height: 100%;

      @media @screen767 {
        width: 34.989%;
        padding-top: 19.826%;
      }

      .timestamp {
        @media @screen767 {
          right: 4px;
          top: 4px;
        }
      }
    }

    .vc-description {
      flex: 1;
      padding-top: 0;
      padding-left: 12px;
      gap: 6px;

      .vc-paragraph {
        .mb-0;
      }
    }
  }

  .video-image {
    width: 100%;
    padding-top: 56.33%;
    border-radius: 8px;
    overflow: hidden;

    @media @screen767 {
      border-radius: 4px;
    }

    .timestamp {
      position: absolute;
      right: 7px;
      top: 4px;
      padding: 3px 8px 4px 8px;
      background: rgba(0, 0, 0, 0.6);
      border-radius: 24px;
      font: @tq-text-small;
      color: @tq-neutral-0;

      @media @screen767 {
        padding: 3px 8px;
        font: @tq-text-tiny;
      }
    }

    .ant-slider {
      position: absolute;
      bottom: 0;
      width: 100%;
      left: 0;
      margin: 0;
      padding: 0;
      height: 4px;
      pointer-events: none;

      .ant-slider-handle {
        opacity: 0;
      }
    }
  }
}

/*video card css end here*/
.video-info-tags {
  width: 100%;
  overflow: hidden;

  @media @screen767 {
    width: auto;
    order: 3;
    margin: 0 -16px;
    padding-left: 16px;
  }
}

.video-button-wrapper {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  flex: 1;

  @media @screen767 {
    width: 100%;
    justify-content: flex-start;
    order: 5;
  }

  .videoaction {
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 8px 12px 8px 8px;
    justify-content: center;
    align-items: center;

    text-align: center;
    min-width: 96px;
    text-align: center;
    max-height: 70px;

    svg {
      width: 24px;
      height: 24px;

      @media @screen767 {
        width: 16px;
        height: 16px;
      }
    }

    @media @screen767 {
      min-width: 62px;
      max-height: 50px;
      flex: 1;
      flex-wrap: wrap;
    }
  }
}

/*video info css start here*/
.video-info-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  @media @screen767 {
    gap: 12px;
  }

  p {
    .paragraph-l;

    @media @screen767 {
      order: 4;
      .paragraph-m;
    }
  }

  .videoinfo-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    @media @screen767 {
      order: 1;
      width: 100%;
      gap: 12px;
    }
  }
}

.video-avatar-group {
  display: flex;
  gap: 16px;
  flex-shrink: 0;
  overflow: auto;
  width: 100%;

  @media @screen767 {
    order: 2;
  }
}

.video-info-section {
  overflow: hidden;

  .slick-list {
    overflow: visible;
  }
}

/*video info css end here*/
