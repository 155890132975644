.quoteslider {
  padding: 100px 0;
  overflow: hidden;

  @media @screen767 {
    padding: 60px 0;
  }

  .quote-wrapper {
    max-width: 758px;
    width: 100%;
    margin: auto;
    text-align: center;

    .quote {
      margin-bottom: 40px;
      .quote;

      @media @screen767 {
        .paragraph-l;
        margin-bottom: 22px;
      }
    }

    .contributor-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;

      .auth-img {
        border-radius: 50%;
        overflow: hidden;
        background: @tq-neutral-600;
      }

      .auth-name {
        .heading-h2;

        @media @screen767 {
          .text-small-bold;
          .mb-8;
        }
      }

      .auth-subtitle {
        .paragraph-l;
        margin-top: -4px;

        @media @screen767 {
          .text-small;
          margin-top: 0;
        }
      }
    }
  }

  .slick-dots {
    position: relative;
    bottom: 0;
    margin-top: 40px;

    @media @screen767 {
      margin-top: 22px;
    }

    li {
      height: 10px;
      width: 10px;
      margin: 0 5px;

      @media @screen767 {
        height: 6px;
        width: 6px;
        margin: 0 3px;
      }

      button {
        height: 10px;
        width: 10px;
        border-radius: 50%;
        background: @tq-neutral-500;

        @media @screen767 {
          height: 6px;
          width: 6px;
        }
      }

      &.slick-active {
        height: 10px;
        width: 10px;

        @media @screen767 {
          height: 6px;
          width: 6px;
        }

        button {
          background: @tq-neutral-900;
        }
      }
    }
  }
}
