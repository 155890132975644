.form-fieldset {
  position: relative;
  .remove-btn {
    position: absolute;
    right: 5px;
  }
}

.form-table {
  .ant-table-footer {
    background: none;
  }
}
