@import url(../variable.less);

/*background color class start here*/
.bg-n-0 {
  background: @tq-neutral-0;
}

.bg-n-100 {
  background: @tq-neutral-100;
}

.bg-n-200 {
  background: @tq-neutral-200;
}

.bg-n-300 {
  background: @tq-neutral-300;
}

.bg-n-400 {
  background: @tq-neutral-400;
}

.bg-n-500 {
  background: @tq-neutral-500;
}

.bg-n-600 {
  background: @tq-neutral-600;
}

.bg-n-700 {
  background: @tq-neutral-700;
}

.bg-n-800 {
  background: @tq-neutral-800;
}

.bg-n-900 {
  background: @tq-neutral-900;
}

.bg-n-1000 {
  background: @tq-neutral-1000;
}

.bg-red {
  background: @tq-red;
}

.bg-red-2 {
  background: @tq-red-2;
}

.bg-red-3 {
  background: @tq-red-3;
}

.bg-yellow {
  background: @tq-yellow;
}

.bg-yellow-2 {
  background: @tq-yellow-2;
}

.bg-yellow-3 {
  background: @tq-yellow-3;
}

.bg-blue {
  background: @tq-blue;
}

.bg-blue-2 {
  background: @tq-blue-2;
}

.bg-blue-3 {
  background: @tq-blue-3;
}

.bg-moss {
  background: @tq-moss;
}

.bg-moss-2 {
  background: @tq-moss-2;
}

.bg-moss-3 {
  background: @tq-moss-3;
}

.bg-ivory {
  background: @tq-ivory;
}

.bg-button-2 {
  background: @tq-button-bg-2;
}

.bg-button-3 {
  background: @tq-button-bg-3;
}

.bg-lighttheme {
  background: @tq-lighttheme;
}

.bg-transparent {
  background: transparent;
}

.bg-tags {
  background: @tq-tag-bg;
}

.bg-white-90 {
  background: @tq-white-90;
}

.bg-white-80 {
  background: @tq-white-80;
}

.bg-white-70 {
  background: @tq-white-70;
}

.bg-black-90 {
  background: @tq-black-90;
}

.bg-black-80 {
  background: @tq-black-80;
}

.bg-black-70 {
  background: @tq-black-70;
}

.bg-border-bottom {
  background: @tq-border-bottom;
}

.bg-border-top {
  background: @tq-border-top;
}

/*background color class end here*/
/*text color class start here*/
.text-n-0 {
  color: @tq-neutral-0;
}

.text-n-100 {
  color: @tq-neutral-100;
}

.text-n-200 {
  color: @tq-neutral-200;
}

.text-n-300 {
  color: @tq-neutral-300;
}

.text-n-400 {
  color: @tq-neutral-400;
}

.text-n-500 {
  color: @tq-neutral-500;
}

.text-n-600 {
  color: @tq-neutral-600;
}

.text-n-700 {
  color: @tq-neutral-700;
}

.text-n-800 {
  color: @tq-neutral-800;
}

.text-n-900 {
  color: @tq-neutral-900;
}

.text-n-1000 {
  color: @tq-neutral-1000;
}

.text-red {
  color: @tq-red;
}

.text-red-2 {
  color: @tq-red-2;
}

.text-red-3 {
  color: @tq-red-3;
}

.text-yellow {
  color: @tq-yellow;
}

.text-yellow-2 {
  color: @tq-yellow-2;
}

.text-yellow-3 {
  color: @tq-yellow-3;
}

.text-blue {
  color: @tq-blue;
}

.text-blue-2 {
  color: @tq-blue-2;
}

.text-blue-3 {
  color: @tq-blue-3;
}

.text-moss {
  color: @tq-moss;
}

.text-moss-2 {
  color: @tq-moss-2;
}

.text-moss-3 {
  color: @tq-moss-3;
}

.text-ivory {
  color: @tq-ivory;
}

.text-button-bg-2 {
  color: @tq-button-bg-2;
}

.text-button-bg-3 {
  color: @tq-button-bg-3;
}

.text-lighttheme {
  color: @tq-lighttheme;
}

.text-white-90 {
  color: @tq-white-90;
}

.text-white-80 {
  color: @tq-white-80;
}

.text-white-70 {
  color: @tq-white-70;
}

.text-black-90 {
  color: @tq-black-90;
}

.text-black-80 {
  color: @tq-black-80;
}

.text-black-70 {
  color: @tq-black-70;
}

.text-border-bottom {
  color: @tq-border-bottom;
}

.text-border-top {
  color: @tq-border-top;
}

/*text color class end here*/
/*border color css start here*/
.b-n-0 {
  border-color: @tq-neutral-0;
}

.b-n-100 {
  border-color: @tq-neutral-100;
}

.b-n-200 {
  border-color: @tq-neutral-200;
}

.b-n-300 {
  border-color: @tq-neutral-300;
}

.b-n-400 {
  border-color: @tq-neutral-400;
}

.b-n-500 {
  border-color: @tq-neutral-500;
}

.b-n-600 {
  border-color: @tq-neutral-600;
}

.b-n-700 {
  border-color: @tq-neutral-700;
}

.b-n-800 {
  border-color: @tq-neutral-800;
}

.b-n-900 {
  border-color: @tq-neutral-900;
}

.b-n-1000 {
  border-color: @tq-neutral-1000;
}

.b-red {
  border-color: @tq-red;
}

.b-red-2 {
  border-color: @tq-red-2;
}

.b-red-3 {
  border-color: @tq-red-3;
}

.b-yellow {
  border-color: @tq-yellow;
}

.b-yellow-2 {
  border-color: @tq-yellow-2;
}

.b-yellow-3 {
  border-color: @tq-yellow-3;
}

.b-blue {
  border-color: @tq-blue;
}

.b-blue-2 {
  border-color: @tq-blue-2;
}

.b-blue-3 {
  border-color: @tq-blue-3;
}

.b-moss {
  border-color: @tq-moss;
}

.b-moss-2 {
  border-color: @tq-moss-2;
}

.b-moss-3 {
  border-color: @tq-moss-3;
}

.b-ivory {
  border-color: @tq-ivory;
}

.b-button-2 {
  border-color: @tq-button-bg-2;
}

.b-button-3 {
  border-color: @tq-button-bg-3;
}

.b-lighttheme {
  border-color: @tq-lighttheme;
}

.b-transparent {
  border-color: transparent;
}

.b-white-90 {
  border-color: @tq-white-90;
}

.b-white-80 {
  border-color: @tq-white-80;
}

.b-white-70 {
  border-color: @tq-white-70;
}

.b-black-90 {
  border-color: @tq-black-90;
}

.b-black-80 {
  border-color: @tq-black-80;
}

.b-black-70 {
  border-color: @tq-black-70;
}

.b-border-bottom {
  border-color: @tq-border-bottom;
}

.b-border-top {
  border-color: @tq-border-top;
}

/*border color css end here*/
/*hover background color class start here*/
.hbg-n-0:hover {
  background: @tq-neutral-0;
}

.hbg-n-100:hover {
  background: @tq-neutral-100;
}

.hbg-n-200:hover {
  background: @tq-neutral-200;
}

.hbg-n-300:hover {
  background: @tq-neutral-300;
}

.hbg-n-400:hover {
  background: @tq-neutral-400;
}

.hbg-n-500:hover {
  background: @tq-neutral-500;
}

.hbg-n-600:hover {
  background: @tq-neutral-600;
}

.hbg-n-700:hover {
  background: @tq-neutral-700;
}

.hbg-n-800:hover {
  background: @tq-neutral-800;
}

.hbg-n-900:hover {
  background: @tq-neutral-900;
}

.hbg-n-1000:hover {
  background: @tq-neutral-1000;
}

.hbg-red:hover {
  background: @tq-red;
}

.hbg-red-2:hover {
  background: @tq-red-2;
}

.hbg-red-3:hover {
  background: @tq-red-3;
}

.hbg-yellow:hover {
  background: @tq-yellow;
}

.hbg-yellow-2:hover {
  background: @tq-yellow-2;
}

.hbg-yellow-3:hover {
  background: @tq-yellow-3;
}

.hbg-blue:hover {
  background: @tq-blue;
}

.hbg-blue-2:hover {
  background: @tq-blue-2;
}

.hbg-blue-3:hover {
  background: @tq-blue-3;
}

.hbg-moss:hover {
  background: @tq-moss;
}

.hbg-moss-2:hover {
  background: @tq-moss-2;
}

.hbg-moss-3:hover {
  background: @tq-moss-3;
}

.hbg-ivory:hover {
  background: @tq-ivory;
}

.hbg-button-2:hover {
  background: @tq-button-bg-2;
}

.hbg-button-3:hover {
  background: @tq-button-bg-3;
}

.hbg-lighttheme:hover {
  background: @tq-lighttheme;
}

.hbg-transparent:hover {
  background: transparent;
}

.hbg-white-90:hover {
  background: @tq-white-90;
}

.hbg-white-80:hover {
  background: @tq-white-80;
}

.hbg-white-70:hover {
  background: @tq-white-70;
}

.hbg-black-90:hover {
  background: @tq-black-90;
}

.hbg-black-80:hover {
  background: @tq-black-80;
}

.hbg-black-70:hover {
  background: @tq-black-70;
}

.hbg-border-bottom:hover {
  background: @tq-border-bottom;
}

.hbg-border-top:hover {
  background: @tq-border-top;
}

/*hover background color class end here*/

/*hover text color class start here*/
.hc-n-0:not(:disabled):hover,
.hc-n-0:not(:disabled):not(.ant-btn-disabled):hover {
  color: @tq-neutral-0;
}

.hc-n-100:not(:disabled):hover,
.hc-n-100:not(:disabled):not(.ant-btn-disabled):hover {
  color: @tq-neutral-100;
}

.hc-n-200:not(:disabled):hover,
.hc-n-200:not(:disabled):not(.ant-btn-disabled):hover {
  color: @tq-neutral-200;
}

.hc-n-300:not(:disabled):hover,
.hc-n-300:not(:disabled):not(.ant-btn-disabled):hover {
  color: @tq-neutral-300;
}

.hc-n-400:not(:disabled):hover,
.hc-n-400:not(:disabled):not(.ant-btn-disabled):hover {
  color: @tq-neutral-400;
}

.hc-n-500:not(:disabled):hover,
.hc-n-500:not(:disabled):not(.ant-btn-disabled):hover {
  color: @tq-neutral-500;
}

.hc-n-600:not(:disabled):hover,
.hc-n-600:not(:disabled):not(.ant-btn-disabled):hover {
  color: @tq-neutral-600;
}

.hc-n-700:not(:disabled):hover,
.hc-n-700:not(:disabled):not(.ant-btn-disabled):hover {
  color: @tq-neutral-700;
}

.hc-n-800:not(:disabled):hover,
.hc-n-800:not(:disabled):not(.ant-btn-disabled):hover {
  color: @tq-neutral-800;
}

.hc-n-900:not(:disabled):hover,
.hc-n-900:not(:disabled):not(.ant-btn-disabled):hover {
  color: @tq-neutral-900;
}

.hc-n-1000:not(:disabled):hover,
.hc-n-1000:not(:disabled):not(.ant-btn-disabled):hover {
  color: @tq-neutral-1000;
}

.hc-red:not(:disabled):hover,
.hc-red:not(:disabled):not(.ant-btn-disabled):hover {
  color: @tq-red;
}

.hc-red-2:not(:disabled):hover,
.hc-red-2:not(:disabled):not(.ant-btn-disabled):hover {
  color: @tq-red-2;
}

.hc-red-3:not(:disabled):hover,
.hc-red-3:not(:disabled):not(.ant-btn-disabled):hover {
  color: @tq-red-3;
}

.hc-yellow:not(:disabled):hover,
.hc-yellow:not(:disabled):not(.ant-btn-disabled):hover {
  color: @tq-yellow;
}

.hc-yellow-2:not(:disabled):hover,
.hc-yellow-2:not(:disabled):not(.ant-btn-disabled):hover {
  color: @tq-yellow-2;
}

.hc-yellow-3:not(:disabled):hover,
.hc-yellow-3:not(:disabled):not(.ant-btn-disabled):hover {
  color: @tq-yellow-3;
}

.hc-blue:not(:disabled):hover,
.hc-blue:not(:disabled):not(.ant-btn-disabled):hover {
  color: @tq-blue;
}

.hc-blue-2:not(:disabled):hover,
.hc-blue-2:not(:disabled):not(.ant-btn-disabled):hover {
  color: @tq-blue-2;
}

.hc-blue-3:not(:disabled):hover,
.hc-blue-3:not(:disabled):not(.ant-btn-disabled):hover {
  color: @tq-blue-3;
}

.hc-moss:not(:disabled):hover,
.hc-moss:not(:disabled):not(.ant-btn-disabled):hover {
  color: @tq-moss;
}

.hc-moss-2:not(:disabled):hover,
.hc-moss-2:not(:disabled):not(.ant-btn-disabled):hover {
  color: @tq-moss-2;
}

.hc-moss-3:not(:disabled):hover,
.hc-moss-3:not(:disabled):not(.ant-btn-disabled):hover {
  color: @tq-moss-3;
}

.hc-ivory:not(:disabled):hover,
.hc-ivory:not(:disabled):not(.ant-btn-disabled):hover {
  color: @tq-ivory;
}

.hc-button-2:not(:disabled):hover,
.hc-button-2:not(:disabled):not(.ant-btn-disabled):hover {
  color: @tq-button-bg-2;
}

.hc-button-3:not(:disabled):hover,
.hc-button-3:not(:disabled):not(.ant-btn-disabled):hover {
  color: @tq-button-bg-3;
}

.hc-lighttheme:not(:disabled):hover,
.hc-lighttheme:not(:disabled):not(.ant-btn-disabled):hover {
  color: @tq-lighttheme;
}

.hc-white-90:not(:disabled):hover,
.hc-white-90:not(:disabled):not(.ant-btn-disabled):hover {
  color: @tq-white-90;
}

.hc-white-80:not(:disabled):hover,
.hc-white-80:not(:disabled):not(.ant-btn-disabled):hover {
  color: @tq-white-80;
}

.hc-white-70:not(:disabled):hover,
.hc-white-70:not(:disabled):not(.ant-btn-disabled):hover {
  color: @tq-white-70;
}

.hc-black-90:not(:disabled):hover,
.hc-black-90:not(:disabled):not(.ant-btn-disabled):hover {
  color: @tq-black-90;
}

.hc-black-80:not(:disabled):hover,
.hc-black-80:not(:disabled):not(.ant-btn-disabled):hover {
  color: @tq-black-80;
}

.hc-black-70:not(:disabled):hover,
.hc-black-70:not(:disabled):not(.ant-btn-disabled):hover {
  color: @tq-black-70;
}

.hc-border-bottom:not(:disabled):hover,
.hc-border-bottom:not(:disabled):not(.ant-btn-disabled):hover {
  color: @tq-border-bottom;
}

.hc-border-top:not(:disabled):hover,
.hc-border-top:not(:disabled):not(.ant-btn-disabled):hover {
  color: @tq-border-top;
}

/*hover text color class end here*/
/*hover text color class start here*/
.hb-n-0:not(:disabled):hover,
.hb-n-0:not(:disabled):not(.ant-btn-disabled):hover {
  border-color: @tq-neutral-0;
}

.hb-n-100:not(:disabled):hover,
.hb-n-100:not(:disabled):not(.ant-btn-disabled):hover {
  border-color: @tq-neutral-100;
}

.hb-n-200:not(:disabled):hover,
.hb-n-200:not(:disabled):not(.ant-btn-disabled):hover {
  border-color: @tq-neutral-200;
}

.hb-n-300:not(:disabled):hover,
.hb-n-300:not(:disabled):not(.ant-btn-disabled):hover {
  border-color: @tq-neutral-300;
}

.hb-n-400:not(:disabled):hover,
.hb-n-400:not(:disabled):not(.ant-btn-disabled):hover {
  border-color: @tq-neutral-400;
}

.hb-n-500:not(:disabled):hover,
.hb-n-500:not(:disabled):not(.ant-btn-disabled):hover {
  border-color: @tq-neutral-500;
}

.hb-n-600:not(:disabled):hover,
.hb-n-600:not(:disabled):not(.ant-btn-disabled):hover {
  border-color: @tq-neutral-600;
}

.hb-n-700:not(:disabled):hover,
.hb-n-700:not(:disabled):not(.ant-btn-disabled):hover {
  border-color: @tq-neutral-700;
}

.hb-n-800:not(:disabled):hover,
.hb-n-800:not(:disabled):not(.ant-btn-disabled):hover {
  border-color: @tq-neutral-800;
}

.hb-n-900:not(:disabled):hover,
.hb-n-900:not(:disabled):not(.ant-btn-disabled):hover {
  border-color: @tq-neutral-900;
}

.hb-n-1000:not(:disabled):hover,
.hb-n-1000:not(:disabled):not(.ant-btn-disabled):hover {
  border-color: @tq-neutral-1000;
}

.hb-red:not(:disabled):hover,
.hb-red:not(:disabled):not(.ant-btn-disabled):hover {
  border-color: @tq-red;
}

.hb-red-2:not(:disabled):hover,
.hb-red-2:not(:disabled):not(.ant-btn-disabled):hover {
  border-color: @tq-red-2;
}

.hb-red-3:not(:disabled):hover,
.hb-red-3:not(:disabled):not(.ant-btn-disabled):hover {
  border-color: @tq-red-3;
}

.hb-yellow:not(:disabled):hover,
.hb-yellow:not(:disabled):not(.ant-btn-disabled):hover {
  border-color: @tq-yellow;
}

.hb-yellow-2:not(:disabled):hover,
.hb-yellow-2:not(:disabled):not(.ant-btn-disabled):hover {
  border-color: @tq-yellow-2;
}

.hb-yellow-3:not(:disabled):hover,
.hb-yellow-3:not(:disabled):not(.ant-btn-disabled):hover {
  border-color: @tq-yellow-3;
}

.hb-blue:not(:disabled):hover,
.hb-blue:not(:disabled):not(.ant-btn-disabled):hover {
  border-color: @tq-blue;
}

.hb-blue-2:not(:disabled):hover,
.hb-blue-2:not(:disabled):not(.ant-btn-disabled):hover {
  border-color: @tq-blue-2;
}

.hb-blue-3:not(:disabled):hover,
.hb-blue-3:not(:disabled):not(.ant-btn-disabled):hover {
  border-color: @tq-blue-3;
}

.hb-moss:not(:disabled):hover,
.hb-moss:not(:disabled):not(.ant-btn-disabled):hover {
  border-color: @tq-moss;
}

.hb-moss-2:not(:disabled):hover,
.hb-moss-2:not(:disabled):not(.ant-btn-disabled):hover {
  border-color: @tq-moss-2;
}

.hb-moss-3:not(:disabled):hover,
.hb-moss-3:not(:disabled):not(.ant-btn-disabled):hover {
  border-color: @tq-moss-3;
}

.hb-ivory:not(:disabled):hover,
.hb-ivory:not(:disabled):not(.ant-btn-disabled):hover {
  border-color: @tq-ivory;
}

.hb-button-2:not(:disabled):hover,
.hb-button-2:not(:disabled):not(.ant-btn-disabled):hover {
  border-color: @tq-button-bg-2;
}

.hb-lighttheme:not(:disabled):hover,
.hb-lighttheme:not(:disabled):not(.ant-btn-disabled):hover {
  border-color: @tq-lighttheme;
}

.hb-button-3:not(:disabled):hover,
.hb-button-3:not(:disabled):not(.ant-btn-disabled):hover {
  border-color: @tq-button-bg-3;
}

.hb-white-90:not(:disabled):hover,
.hb-white-90:not(:disabled):not(.ant-btn-disabled):hover {
  border-color: @tq-white-90;
}

.hb-white-80:not(:disabled):hover,
.hb-white-80:not(:disabled):not(.ant-btn-disabled):hover {
  border-color: @tq-white-80;
}

.hb-white-70:not(:disabled):hover,
.hb-white-70:not(:disabled):not(.ant-btn-disabled):hover {
  border-color: @tq-white-70;
}

.hb-black-90:not(:disabled):hover,
.hb-black-90:not(:disabled):not(.ant-btn-disabled):hover {
  border-color: @tq-black-90;
}

.hb-black-80:not(:disabled):hover,
.hb-black-80:not(:disabled):not(.ant-btn-disabled):hover {
  border-color: @tq-black-80;
}

.hb-black-70:not(:disabled):hover,
.hb-black-70:not(:disabled):not(.ant-btn-disabled):hover {
  border-color: @tq-black-70;
}

.hb-border-bottom:not(:disabled):hover,
.hb-border-bottom:not(:disabled):not(.ant-btn-disabled):hover {
  border-color: @tq-border-bottom;
}

.hb-border-top:not(:disabled):hover,
.hb-border-top:not(:disabled):not(.ant-btn-disabled):hover {
  border-color: @tq-border-top;
}

/*hover border color class end here*/
