/*breakpoint mixin start here*/
@screen1450: ~'only screen and (max-width: 1450px)';
@screen1150: ~'only screen and (max-width: 1150px)';
@screen1024: ~'only screen and (max-width: 1024px)';
@screen991: ~'only screen and (max-width: 991px)';
@minScreen991: ~'only screen and (min-width: 991px)';
@screen767: ~'only screen and (max-width: 767px)';
@screen540: ~'only screen and (max-width: 540px)';
@screen375: ~'only screen and (max-width: 375px)';
@screen320: ~'only screen and (max-width: 320px)';

/*breakpoint mixin end here*/
/*breakpoint mixin end here*/
:root {
  --tq-primary-font: 'Founders Grotesk';
  --tq-primary-font-light: 'Founders Grotesk light';
  --tq-primary-font-mono: 'Founders Grotesk Mono';
  --tq-secondary-font: 'Sanomat';
  --tq-neutral-0: #ffffff;
  --tq-neutral-100: #fbf7f1;
  --tq-neutral-200: #faf4e8;
  --tq-neutral-300: #f9eede;
  --tq-neutral-400: #f0e4d1;
  --tq-neutral-500: #b0adab;
  --tq-neutral-600: #9e9991;
  --tq-neutral-700: #69655f;
  --tq-neutral-800: #3d3b38;
  --tq-neutral-900: #1a1714;
  --tq-neutral-1000: #000000;
  --tq-footer-bg: #000000;
  --tq-red: #fe7d61;
  --tq-red-2: #fe4819;
  --tq-red-3: #942519;
  --tq-yellow: #edd680;
  --tq-yellow-2: #f4b213;
  --tq-yellow-3: #806025;
  --tq-blue: #d2d9d8;
  --tq-blue-2: #aecacd;
  --tq-blue-3: #465a66;
  --tq-moss: #c9bf95;
  --tq-moss-2: #a49562;
  --tq-moss-3: #5c512e;
  --tq-ivory: #f9eede;
  --tq-lighttheme: #646464;
  --tq-white-90: #eeeeee;
  --tq-white-80: #dddddd;
  --tq-white-70: #cccccc;
  --tq-black-90: #1a1a1a;
  --tq-black-80: #333333;
  --tq-black-70: #4d4d4d;
  --tq-border-bottom: #ddd3c5;
  --tq-border-top: #dedede;
  --tq-button-bg-2: rgba(31, 29, 25, 0.1);
  --tq-button-bg-3: rgba(251, 247, 241, 0.1);
  --tq-tag-bg: rgba(255, 255, 255, 0.1);
  --tq-display-h0: normal 300 60px/72px var(--tq-secondary-font);
  --tq-display-h1: normal 300 40px/48px var(--tq-secondary-font);
  --tq-display-h2: normal 400 32px/38px var(--tq-secondary-font);
  --tq-display-h3: normal 400 28px/34px var(--tq-secondary-font);
  --tq-display-h4: normal 400 24px/29px var(--tq-secondary-font);
  --tq-display-h5: normal 400 20px/24px var(--tq-secondary-font);
  --tq-display-h6: normal 400 20px/24px var(--tq-secondary-font);

  --tq-heading-h1: normal 500 40px/47px var(--tq-primary-font);
  --tq-heading-h2: normal 500 32px/38px var(--tq-primary-font);
  --tq-heading-h3: normal 500 28px/33px var(--tq-primary-font);
  --tq-heading-h4: normal 500 24px/28px var(--tq-primary-font);
  --tq-heading-h5: normal 500 20px/24px var(--tq-primary-font);
  --tq-heading-h6: normal 500 18px/21px var(--tq-primary-font);
  --tq-heading-h7: normal 500 16px/19px var(--tq-primary-font);

  --tq-text-lg: normal 400 18px/1 var(--tq-primary-font);
  --tq-text-lg-bold: normal 500 18px/1 var(--tq-primary-font);

  --tq-text-md: normal 400 16px/1 var(--tq-primary-font);
  --tq-text-md-bold: normal 500 16px/1 var(--tq-primary-font);
  --tq-text-md-kicker: normal 400 18px/1 var(--tq-primary-font-mono);

  --tq-text-small: normal 400 14px/1 var(--tq-primary-font);
  --tq-text-small-bold: normal 500 14px/1 var(--tq-primary-font);
  --tq-text-small-kicker: normal 400 12px/1 var(--tq-primary-font-mono);

  --tq-text-tiny: normal 400 12px/14px var(--tq-primary-font);
  --tq-text-tiny-bold: normal 500 12px/14px var(--tq-primary-font);
  --tq-text-tiny-kicker: normal 400 10px/1 var(--tq-primary-font-mono);

  --tq-button-xl: normal 500 22px/1 var(--tq-primary-font);
  --tq-button-l: normal 500 18px/1 var(--tq-primary-font);
  --tq-button-m: normal 500 16px/1 var(--tq-primary-font);
  --tq-button-s: normal 500 14px/1 var(--tq-primary-font);

  --tq-label-xl: normal 600 20px/110% var(--tq-primary-font);
  --tq-label-l: normal 600 18px/110% var(--tq-primary-font);
  --tq-label-m: normal 600 16px/110% var(--tq-primary-font);
  --tq-label-s: normal 600 14px/110% var(--tq-primary-font);
  --tq-label-xs: normal 600 12px/110% var(--tq-primary-font);

  --tq-paragraph-s: normal 400 14px/140% var(--tq-primary-font);
  --tq-paragraph-m: normal 400 16px/140% var(--tq-primary-font);
  --tq-paragraph-l: normal 400 18px/140% var(--tq-primary-font);
  --tq-paragraph-l-xl: normal 400 24px/140% var(--tq-primary-font);

  --tq-quote: normal 400 32px/140% var(--tq-primary-font);

  @media (min-width: 991px) {
    --tq-display-h1: normal 300 48px/58px var(--tq-secondary-font);
    --tq-display-h2: normal 300 40px/48px var(--tq-secondary-font);
    --tq-display-h3: normal 400 32px/38px var(--tq-secondary-font);
    --tq-display-h4: normal 400 28px/34px var(--tq-secondary-font);
    --tq-display-h5: normal 400 24px/29px var(--tq-secondary-font);

    --tq-text-lg: normal 400 22px/1 var(--tq-primary-font);
    --tq-text-lg-bold: normal 500 22px/1 var(--tq-primary-font);

    --tq-text-md: normal 400 20px/1 var(--tq-primary-font);
    --tq-text-md-bold: normal 500 20px/1 var(--tq-primary-font);

    --tq-text-small: normal 400 18px/1 var(--tq-primary-font);
    --tq-text-small-bold: normal 500 18px/1 var(--tq-primary-font);
    --tq-text-small-kicker: normal 400 16px/1 var(--tq-primary-font-mono);

    --tq-text-tiny: normal 400 16px/1 var(--tq-primary-font);
    --tq-text-tiny-bold: normal 500 16px/1 var(--tq-primary-font);
    --tq-text-tiny-kicker: normal 400 14px/1 var(--tq-primary-font-mono);

    --tq-button-l: normal 500 20px/1 var(--tq-primary-font);
    --tq-button-m: normal 500 18px/1 var(--tq-primary-font);
    --tq-button-s: normal 500 16px/1 var(--tq-primary-font);

    --tq-label-xxl: normal 600 40px/110% var(--tq-primary-font);
    --tq-label-xl: normal 600 22px/110% var(--tq-primary-font);
    --tq-label-l: normal 600 20px/110% var(--tq-primary-font);
    --tq-label-m: normal 600 18px/110% var(--tq-primary-font);
    --tq-label-s: normal 600 16px/110% var(--tq-primary-font);
    --tq-label-xs: normal 600 14px/110% var(--tq-primary-font);

    --tq-paragraph-s: normal 400 16px/140% var(--tq-primary-font);
    --tq-paragraph-m: normal 400 18px/140% var(--tq-primary-font);
    --tq-paragraph-l: normal 400 20px/140% var(--tq-primary-font);
  }
}

// ColorPicker Variables
@white-color: #ffffff;
@black-color: #000000;
@black-light: #3a3a3a;
@input-border: #d9d9d9;
@border-color: #8b8b8b;
@status-color: #dbdbdb;
//global style variables
@primary-color: #4b4441;
@secondary-color: #fa8c15;
@text-color: @primary-color;
@text-color-secondary: #595959;
@heading-color: #535353;
@header-text-color: #262626;
@layout-body-background: #b6b4b3;
@layout-header-background: @layout-body-background;
@layout-footer-background: @layout-body-background;
@layout-sider-background: @layout-body-background;
@component-background: @layout-body-background;
@layout-header-background-dark: #003365;
// @layout-footer-background: #fffffe;
@body-background: #f5f5f5;
@nav-bar-bg: #fffffc;
@nav-bar-text-color: @text-color;

@nav-dark-bg: #003366;
@nav-dark-text-color: #038fdd;
@menu-dark-bg: @nav-dark-bg;
@menu-dark-color: @nav-dark-text-color;
@menu-dark-highlight-color: @secondary-color;
@menu-dark-arrow-color: @nav-dark-text-color;
@hor-nav-text-color: #fffffd;
@menu-item-color: @primary-color;
@menu-inline-submenu-bg: @layout-body-background;

@border-radius-base: 6px;
@border-radius-sm: 4px;
@border-radius-lg: 10px;
@layout-header-padding: 0 32px;
@card-actions-li-margin: 12px 24px;

// @font-size-base: 14px;
// @font-size-lg: @font-size-base + 0.1em;
// @font-size-sm: 12px;

// font-size: clamp(min, value, max);

@heading-1-size: clamp(25px, 4.6vw, 38px);
@heading-2-size: clamp(20px, 4vw, 30px);
@heading-3-size: clamp(18px, 3.8vw, 24px);
@heading-4-size: clamp(16px, 3vw, 20px);
@heading-5-size: clamp(14px, 2.5vw, 16px);

@border-color-base: @primary-color;
@border-color-split: @primary-color;

@input-color: @primary-color;
@input-disabled-bg: @layout-body-background;
@input-placeholder-color: @primary-color;

@table-header-bg: @layout-body-background;
@item-hover-bg: @primary-color;

@radio-button-checked-bg: @primary-color;
@radio-button-hover-color: @white-color;
@radio-button-active-color: @white-color;
@radio-disabled-button-checked-bg: @white-color;
@radio-disabled-button-checked-color: @white-color;

@btn-disable-bg: @layout-body-background;
/*typography css end here*/
//*all variable list link are here*//
/**https://github.com/ant-design/ant-design/blob/4.x-stable/components/style/themes/default.less**/

/*web variable start here*/
/*px to em convert function start here*/

/*px to em convert function end here*/
/*font variable start here*/
@tq-primary-font: var(--tq-primary-font);
@tq-secondary-font: var(--tq-secondary-font);

@tq-display-h0: var(--tq-display-h0);
@tq-display-h1: var(--tq-display-h1);
@tq-display-h2: var(--tq-display-h2);
@tq-display-h3: var(--tq-display-h3);
@tq-display-h4: var(--tq-display-h4);
@tq-display-h5: var(--tq-display-h5);
@tq-display-h6: var(--tq-display-h6);

@tq-heading-h1: var(--tq-heading-h1);
@tq-heading-h2: var(--tq-heading-h2);
@tq-heading-h3: var(--tq-heading-h3);
@tq-heading-h4: var(--tq-heading-h4);
@tq-heading-h5: var(--tq-heading-h5);
@tq-heading-h6: var(--tq-heading-h6);
@tq-heading-h7: var(--tq-heading-h7);

@tq-text-lg: var(--tq-text-lg);
@tq-text-lg-bold: var(--tq-text-lg-bold);

@tq-text-md: var(--tq-text-md);
@tq-text-md-bold: var(--tq-text-md-bold);
@tq-text-md-kicker: var(--tq-text-md-kicker);

@tq-text-small: var(--tq-text-small);
@tq-text-small-bold: var(--tq-text-small-bold);
@tq-text-small-kicker: var(--tq-text-small-kicker);

@tq-text-tiny: var(--tq-text-tiny);
@tq-text-tiny-bold: var(--tq-text-tiny-bold);
@tq-text-tiny-kicker: var(--tq-text-tiny-kicker);

@tq-button-xl: var(--tq-button-xl);
@tq-button-l: var(--tq-button-l);
@tq-button-m: var(--tq-button-m);
@tq-button-s: var(--tq-button-s);

@tq-label-xxl: var(--tq-label-xxl);
@tq-label-xl: var(--tq-label-xl);
@tq-label-l: var(--tq-label-l);
@tq-label-m: var(--tq-label-m);
@tq-label-s: var(--tq-label-s);
@tq-label-xs: var(--tq-label-xs);

@tq-paragraph-s: var(--tq-paragraph-s);
@tq-paragraph-m: var(--tq-paragraph-m);
@tq-paragraph-l: var(--tq-paragraph-l);
@tq-paragraph-l-xl: var(--tq-paragraph-l-xl);

@tq-quote: var(--tq-quote);

/*font variable end here*/
/*color variable start here*/
@tq-neutral-0: var(--tq-neutral-0);
@tq-neutral-100: var(--tq-neutral-100);
@tq-neutral-200: var(--tq-neutral-200);
@tq-neutral-300: var(--tq-neutral-300);
@tq-neutral-400: var(--tq-neutral-400);
@tq-neutral-500: var(--tq-neutral-500);
@tq-neutral-600: var(--tq-neutral-600);
@tq-neutral-700: var(--tq-neutral-700);
@tq-neutral-800: var(--tq-neutral-800);
@tq-neutral-900: var(--tq-neutral-900);
@tq-neutral-1000: var(--tq-neutral-1000);
@tq-footer-bg: var(--tq-footer-bg);
@tq-red: var(--tq-red);
@tq-red-2: var(--tq-red-2);
@tq-red-3: var(--tq-red-3);
@tq-yellow: var(--tq-yellow);
@tq-yellow-2: var(--tq-yellow-2);
@tq-yellow-3: var(--tq-yellow-3);
@tq-blue: var(--tq-blue);
@tq-blue-2: var(--tq-blue-2);
@tq-blue-3: var(--tq-blue-3);
@tq-moss: var(--tq-moss);
@tq-moss-2: var(--tq-moss-2);
@tq-moss-3: var(--tq-moss-3);
@tq-ivory: var(--tq-ivory);
@tq-lighttheme: var(--tq-lighttheme);
@tq-white-90: var(--tq-white-90);
@tq-white-80: var(--tq-white-80);
@tq-white-70: var(--tq-white-70);
@tq-black-90: var(--tq-black-90);
@tq-black-80: var(--tq-black-80);
@tq-black-70: var(--tq-black-70);
@tq-border-bottom: var(--tq-border-bottom);
@tq-border-top: var(--tq-border-top);
@tq-button-bg-2: var(--tq-button-bg-2);
@tq-button-bg-3: var(--tq-button-bg-3);
@tq-tag-bg: var(--tq-tag-bg);
/*color variable end here*/

/*shadow variable start here*/
@tq-shadow-top: inset 0px 1px 0px #dedede;
@tq-shadow-bottom: inset 0px -1px 0px rgba(61, 59, 56, 0.15);
@tq-shadow-drawer: 0px -14px 6px rgba(53, 36, 10, 0.01),
  0px -8px 5px rgba(53, 36, 10, 0.05), 0px -4px 4px rgba(53, 36, 10, 0.09),
  0px -1px 2px rgba(53, 36, 10, 0.1), 0px 0px 0px rgba(53, 36, 10, 0.1);
@tq-shadow-s: 0px 19px 8px rgba(78, 68, 54, 0.02),
  0px 11px 6px rgba(78, 68, 54, 0.08), 0px 5px 5px rgba(78, 68, 54, 0.13),
  0px 1px 3px rgba(78, 68, 54, 0.15), 0px 0px 0px rgba(78, 68, 54, 0.15);
@tq-shadow-m: 0px 45px 18px rgba(78, 68, 54, 0.02),
  0px 25px 15px rgba(78, 68, 54, 0.08), 0px 11px 11px rgba(78, 68, 54, 0.13),
  0px 3px 6px rgba(78, 68, 54, 0.15), 0px 0px 0px rgba(78, 68, 54, 0.15);
@tq-shadow-l: 0px 19px 8px rgba(78, 68, 54, 0.02),
  0px 11px 6px rgba(78, 68, 54, 0.08), 0px 5px 5px rgba(78, 68, 54, 0.13),
  0px 1px 3px rgba(78, 68, 54, 0.15), 0px 0px 0px rgba(78, 68, 54, 0.15);
/*shadow variable end here*/
/*web variable end here*/
