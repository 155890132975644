.heroblock {
  padding-top: 196px;
  padding-bottom: 215px;
  text-align: center;

  @media @screen767 {
    text-align: left;
    padding-top: 131px;
    padding-bottom: 101px;
  }

  img {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &::before {
    @media @screen767 {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      background: linear-gradient(
        360deg,
        #000000 0%,
        rgba(0, 0, 0, 0.876677) 48.8%,
        rgba(0, 0, 0, 0) 96.6%
      );
      height: 412px;
      width: 100%;
      z-index: 1;
    }
  }

  video {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .herocontent {
    max-width: 895px;
    width: 100%;
    margin: auto;
    position: relative;
    z-index: 2;

    .title {
      .display-h0;
      overflow: hidden;
      white-space: pre-wrap;

      @media @screen767 {
        .display-h1;
      }
    }

    .description {
      .paragraph-l-xl;
      overflow: hidden;
      white-space: pre-wrap;

      @media @screen767 {
        .paragraph-l;
      }
    }

    .date-heading {
      .heading-h4;

      @media @screen767 {
        .heading-h5;
        .mb-16;
      }
    }

    .hero-button-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 32px;
      .mt-48;

      @media @screen767 {
        gap: 12px;
        .mt-32;
      }

      button {
        @media @screen767 {
          flex: 1;
        }
      }
    }
  }
}
