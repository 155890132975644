.login-wrapper {
  padding: 48px 0;

  @media @screen767 {
    padding: 32px 0;
  }

  .login-btn {
    margin-top: 32px;

    @media @screen767 {
      margin-top: 24px;
    }

    button.cs-button.ant-btn.ant-btn-lg {
      .button-xl;

      @media @screen767 {
        .button-l;
      }
    }
  }

  &.contact-page {
    padding: 32px 0;

    .loginblock {
      max-width: 576px;
      padding: 32px 48px;

      @media @screen767 {
        padding: 0;
      }
    }

    .ant-form-item {
      margin: 0;
    }

    .form-group {
      textarea {
        min-height: 94px;

        @media @screen767 {
          min-height: 78px;
        }
      }
    }
  }

  .loginblock {
    max-width: 528px;
    width: 100%;
    margin: auto;
    background: @tq-neutral-200;
    box-shadow: 0px 45px 18px rgba(78, 68, 54, 0.02),
      0px 25px 15px rgba(78, 68, 54, 0.08), 0px 11px 11px rgba(78, 68, 54, 0.13),
      0px 3px 6px rgba(78, 68, 54, 0.15), 0px 0px 0px rgba(78, 68, 54, 0.15);
    border-radius: 16px;
    overflow: hidden;
    padding: 24px;

    @media @screen767 {
      box-shadow: none;
      max-width: 100%;
      background: transparent;
      padding: 0;
      border-radius: 0;
    }

    &.verification {
      .form-group {
        margin-bottom: 0;
      }
    }

    .login-title {
      .mb-32;

      @media @screen767 {
        margin-bottom: 24px;
      }

      h3 {
        .text-n-1000;

        @media @screen767 {
          margin-bottom: 4px;
        }
      }

      p {
        .paragraph-l;

        a {
          .text-tiny;
          color: #004bd9;
          margin-top: 2px;
          text-decoration: underline;

          @media @screen767 {
            font: var(--tq-paragraph-m);
            font-weight: 500;
          }
        }
      }
    }

    .login-policy {
      .text-tiny;
      margin-top: 6px;

      a {
        font-weight: 500;
        text-decoration: underline;
        color: #004bd9;

        &:hover {
          color: #2b00d9;
        }
      }

      & + .login-footer {
        margin-top: 8px;
      }
    }

    .login-footer {
      .text-tiny;
      .text-center;
      margin-top: 12px;

      @media @screen767 {
        margin-top: 8px;
        font: var(--tq-paragraph-m);
        font-weight: 500;
        line-height: 22.5px;
      }

      a {
        .text-tiny;
        color: #004bd9;
        margin-top: 2px;
        text-decoration: underline;
        font-weight: 500;

        @media @screen767 {
          font: var(--tq-paragraph-m);
          font-weight: 500;
        }

        &:hover {
          color: #2b00d9;
        }
      }
    }
  }
}

.account-setting {
  padding: 32px 0;

  @media @screen767 {
    padding: 0;
  }

  .account-page-title {
    display: none;

    @media @screen767 {
      display: block;
      padding: 16px;
      .heading-h5;
      border-bottom: 1px solid @tq-border-bottom;
    }
  }

  .account-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    align-items: flex-start;

    .account-nav {
      background: @tq-neutral-200;
      box-shadow: @tq-shadow-s;
      border-radius: 8px;
      max-width: 268px;
      width: 100%;

      @media @screen767 {
        display: none;
      }

      ul {
        list-style: none;

        li {
          &:not(:last-of-type) {
            border-bottom: 1px solid @tq-border-bottom;
          }

          &.active {
            a {
              .text-n-900;
              .text-small-bold;
            }
          }

          a {
            .text-small;
            display: flex;
            align-items: center;
            .text-n-700;
            gap: 12px;
            padding: 16px 24px;
          }
        }
      }
    }

    .account-content-area {
      flex: 1;

      .account-info-block {
        background: @tq-neutral-200;
        /* Shadow / M */

        box-shadow: 0px 45px 18px rgba(78, 68, 54, 0.02),
          0px 25px 15px rgba(78, 68, 54, 0.08),
          0px 11px 11px rgba(78, 68, 54, 0.13),
          0px 3px 6px rgba(78, 68, 54, 0.15), 0px 0px 0px rgba(78, 68, 54, 0.15);
        border-radius: 16px;
        max-width: 528px;
        width: 100%;
        padding: 24px;

        @media @screen767 {
          min-height: 100vh;
          padding: 0;
          padding-top: 24px;
          box-shadow: none;
          background: transparent;
          max-width: 100%;
        }

        .button-wrapper {
          &.fixed-button {
            @media @screen767 {
              position: fixed;
              bottom: 0;
              background: @tq-neutral-200;
              padding: 16px;
              width: 100%;
              left: 0;
              bottom: 0;
              box-shadow: 0px -14px 6px rgba(53, 36, 10, 0.01),
                0px -4px 4px rgba(53, 36, 10, 0.05),
                0px -1px 2px rgba(53, 36, 10, 0.05);
              border-radius: 8px 8px 0px 0px;
            }
          }

          .button-xl;
        }

        &.faqs {
          max-width: 100%;
          padding-bottom: 4px;

          .faq-section-title {
            margin: 0;
          }

          .faq-section,
          .container {
            padding: 0;
            background: @tq-neutral-200;

            @media @screen767 {
              background: transparent;
            }
          }

          .container-marketing {
            max-width: 100%;
          }

          .ant-collapse {
            background: @tq-neutral-200;

            @media @screen767 {
              background: transparent;
            }
          }
        }

        .button-wrapper {
          margin-top: 32px;
        }

        .profile {
          .relative;
          margin-bottom: 24px;

          .ant-upload {
            position: absolute;
            bottom: 2px;
            right: 2px;

            button {
              height: 36px;
              width: 36px;
              min-width: 36px;
              padding: 0;

              @media @screen767 {
                height: 28px;
                width: 28px;
                min-width: 28px;
              }

              svg {
                @media @screen767 {
                  height: 16px;
                  width: 16px;
                  min-width: 16px;
                }
              }
            }
          }
        }

        .r-wrapper {
          display: flex;
          flex-direction: column;

          @media @screen767 {
            flex-direction: row;
            gap: 8px;
          }
        }

        .form-group {
          .mb-16;

          &.r-50 {
            @media @screen767 {
              width: 50%;
            }
          }

          .label {
            .text-small-bold;
            .mb-8;

            @media @screen767 {
              .text-md-bold;
              .mb-6;
            }
          }
        }

        .donation-block {
          padding: 24px 32px;
          background: @tq-blue-2;
          border-radius: 8px;
          overflow: hidden;
          text-align: center;
          .mb-20;

          @media @screen767 {
            padding: 16px;
            text-align: left;
            margin-bottom: 24px;
          }

          .button-wrapper {
            display: flex;

            @media @screen767 {
              .mt-24;
            }

            button {
              flex: 1;
              border-radius: 0;
              height: 30px;
              padding: 0;
              .label-l;

              @media @screen767 {
                height: 28px;
              }

              &:first-child {
                border-right: 1px solid @tq-blue-3;
              }
            }
          }

          &:last-child {
            .mb-32;
          }

          h4 {
            .display-h4;
            .mb-4;
          }

          p {
            .text-md;
            letter-spacing: 0.02em;

            @media @screen767 {
              .text-lg;
            }
          }

          & + .cs-accordion {
            @media @screen767 {
              margin-top: 0;
            }

            .ant-collapse-item > .ant-collapse-header {
              @media @screen767 {
                padding: 14px 16px;
              }
            }
          }
        }
      }
    }
  }
}

.donation-list {
  .item {
    display: flex;
    align-items: flex-start;

    &:not(:last-child) {
      padding-bottom: 8px;
      border-bottom: 1px solid @tq-border-bottom;
      margin-bottom: 8px;

      @media @screen767 {
        padding-bottom: 4px;
        margin-bottom: 10px;
      }
    }

    .donation-list-dec {
      flex: 1;

      strong {
        font-weight: 500;
      }

      p {
        margin-bottom: 6px;
      }
    }

    .donation-list-price {
      .text-md-bold;
    }
  }
}

.header-profile-popover {
  min-width: 383px;
  box-shadow: 0px 45px 18px rgba(78, 68, 54, 0.02),
    0px 25px 15px rgba(78, 68, 54, 0.08), 0px 11px 11px rgba(78, 68, 54, 0.13),
    0px 3px 6px rgba(78, 68, 54, 0.15), 0px 0px 0px rgba(78, 68, 54, 0.15);
  border-radius: 8px;
  padding: 0;

  @media @screen767 {
    left: 0 !important;
    min-width: 100%;
    top: 64px !important;
    bottom: 0;
    height: calc(100% - 64px);
    background: @tq-neutral-200;
    position: fixed;
    box-shadow: unset;
    border-radius: 0;
  }

  .ant-popover-inner {
    @media @screen767 {
      box-shadow: unset;
      background: transparent;
    }
  }

  .ant-popover-inner-content {
    padding: 0;
  }

  .ant-popover-arrow {
    display: none;
  }

  .account-profile-header {
    text-align: center;
    padding: 24px;
    padding-bottom: 0;

    h5 {
      .display-h5;
      margin-top: 16px;
      margin-bottom: 4px;

      @media @screen767 {
        .display-h4;
      }
    }

    p {
      .text-small;
      .text-n-800;

      @media @screen767 {
        .text-lg;
      }

      &:not(:last-of-type) {
        .mb-8;
      }
    }
  }

  .profile-btn {
    padding: 32px 24px;
    border-top: 1px solid @tq-border-bottom;

    @media @screen767 {
      padding: 32px 16px;
    }

    button {
      padding: 0 24px;

      @media @screen767 {
        background: transparent;
      }
    }
  }

  .profile-menu {
    padding-top: 32px;

    ul {
      list-style: none;

      li {
        border-top: 1px solid @tq-border-bottom;

        a {
          .text-small;
          .text-n-900;
          display: flex;
          align-items: center;
          padding: 16px 24px;
          position: relative;

          @media @screen767 {
            padding: 16px;
          }

          &:hover {
            .text-n-800;
          }

          .Caret {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
          }

          svg {
            margin-right: 8px;
          }
        }
      }
    }
  }
}

.form-group-two-grid {
  display: flex;

  gap: 16px;

  @media @screen767 {
    gap: 8px;
  }

  .form-group {
    width: 100%;
  }

  .ant-form-item {
    width: 100%;
  }
}

.form-group {
  .mb-16;

  @media @screen767 {
    margin-bottom: 20px;
  }

  .ant-form-item {
    margin: 0;
  }

  .label {
    .text-small-bold;
    .mb-8;

    @media @screen767 {
      margin-bottom: 6px;
      .text-md-bold;
    }
  }

  a {
    .paragraph-s;
    color: #004bd9;
    margin-top: 2px;
    display: inline-block;
    font-weight: 500;

    @media @screen767 {
      margin-top: 0;
      display: block;
    }

    &:hover {
      color: #2b00d9;
    }
  }
}

.ant-form-item-label {
  label {
    span {
      .text-md;

      @media @screen767 {
        .text-lg;
      }
    }
  }
}
