.information-block {
  padding: 100px 0;

  @media @screen767 {
    padding: 60px 0;
  }

  .information-wrapper {
    display: flex;
    align-items: center;
    gap: 100px;
    max-width: 1240px;
    width: 100%;
    margin: auto;

    @media @screen991 {
      gap: 50px;
    }

    @media @screen767 {
      flex-direction: column-reverse;
      gap: 32px;
    }

    &.info-rev {
      flex-direction: row-reverse;

      @media @screen767 {
        flex-direction: column-reverse;
      }
    }

    .information-image {
      padding-top: 24.182%;
      max-width: 570px;
      width: 100%;
      filter: drop-shadow(0px 45px 18px rgba(78, 68, 54, 0.02)) drop-shadow(0px 25px 15px rgba(78, 68, 54, 0.08)) drop-shadow(0px 11px 11px rgba(78, 68, 54, 0.13)) drop-shadow(0px 3px 6px rgba(78, 68, 54, 0.15)) drop-shadow(0px 0px 0px rgba(78, 68, 54, 0.15));
      ;

      border-radius: 16px;
      overflow: hidden;

      &.square-img {
        padding-top: 43.549%;
      }

      &.portrait-img {
        padding-top: 51.613%;
      }

      @media @screen767 {
        max-width: 100%;
        padding-top: 56.852%;
        filter: unset;
      }

      img {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        object-fit: cover;
      }
    }

    .information-content {
      max-width: 570px;
      width: 100%;

      @media @screen767 {
        max-width: 100%;
      }

      h3 {
        .mb-16;

        @media @screen767 {
          .mb-24
        }
      }

      .description {
        .paragraph-l-xl;
        .mb-32;

        @media @screen767 {
          .paragraph-m;
          .mb-24;
        }
      }

      .button-wrapper {
        display: flex;
        align-items: center;
        gap: 32px;

        @media @screen991 {
          gap: 24px;
        }

        @media @screen767 {
          gap: 12px
        }

        button {
          @media @screen767 {
            flex: 1;
          }
        }
      }
    }
  }

}