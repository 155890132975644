.donate-wrapper {
  padding: 100px 0;

  @media @screen767 {
    padding: 60px 0;
  }

  .d-title {
    .display-h1;
    .mb-16;

    @media @screen767 {
      .display-h3;
      margin-bottom: 20px;
    }
  }

  .d-dec {
    .paragraph-m;
    max-width: 677px;
    margin: auto;

    @media @screen767 {
      .paragraph-m;
    }
  }

  .amount-wrapper {
    max-width: 1240px;
    width: 100%;
    margin: auto;
    margin-top: 40px;
    display: flex;
    background: @tq-neutral-200;
    border-radius: 16px;
    box-shadow: @tq-shadow-s;
    overflow: hidden;

    @media @screen767 {
      flex-direction: column;
      margin-top: 20px;
    }

    .amount-video-section {
      width: 50%;
      flex: 0 0 50%;
      padding: 54px 70px;
      overflow: hidden;

      @media @screen767 {
        width: 100%;
        flex: 0 0 100%;
        padding: 24px 16px;
      }

      &.gradiant-background {
        position: relative;

        .video-section-title,
        .video-block {
          position: relative;
          z-index: 1;
        }

        &::before {
          content: '';
          // background-image: url(/images/gradiantbg.png);
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          filter: blur(80px);
        }

        &::after {
          content: '';
          background: rgba(0, 0, 0, 0.65);
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          filter: blur(80px);
        }
      }

      &.amount-video-section-flex {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      .video-block {
        &.video-flex {
          display: flex;
          align-items: center;
          gap: 16px;

          .video-img {
            max-width: 214px;
            margin: 0;
            padding-top: 25%;

            @media @screen767 {
              padding-top: 18.65%;
              max-width: 114px;
              width: 77.5%;
            }
          }

          p {
            .heading-h4;

            @media @screen767 {
              .text-small-bold;
            }
          }
        }

        .video-img {
          border-radius: 8px;
          overflow: hidden;
          width: 100%;
          position: relative;
          padding-top: 63.125%;
          .mb-16;

          @media @screen767 {
            padding-top: 56.274%;
            margin-bottom: 12px;
          }

          img {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }

        p {
          .paragraph-l-xl;

          @media @screen767 {
            .text-small-bold;
          }
        }
      }

      .video-section-title {
        @media @screen767 {
          margin-bottom: 28px;
        }

        h3 {
          .display-h3;

          @media @screen767 {
            .display-h4;
          }
        }

        p {
          .paragraph-l;

          @media @screen767 {
            .paragraph-m;
          }
        }
      }
    }

    .amount-form {
      width: 50%;
      flex: 0 0 50%;
      padding: 54px 70px;

      @media @screen767 {
        width: 100%;
        flex: 0 0 100%;
        padding: 24px 16px;
      }

      .ant-form-item {
        @media @screen767 {
          margin-bottom: 12px;
        }
      }

      .ant-input-affix-wrapper > input.ant-input {
        @media @screen767 {
          font-size: 18px;
        }
      }

      .cs-radio-form {
        margin-bottom: 40px;

        @media @screen767 {
          margin-bottom: 24px;
        }

        .ant-radio-wrapper {
          margin-right: 16px;

          .ant-radio {
            & + span {
              .paragraph-m;
              padding: 0;
              margin-left: 6px;
            }
          }
        }
      }

      .form-group {
        @media @screen767 {
          margin-bottom: 0;
        }
      }

      .form-label {
        .heading-h6;
        .mb-8;

        @media @screen767 {
          .heading-h7;
          margin-bottom: 6px;
        }
      }

      .cs-radiogroup .ant-radio-group .ant-radio-button-wrapper {
        background: transparent;
      }

      .cs-radiogroup
        .ant-radio-group
        .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
        background: @tq-neutral-900;
        color: @tq-white-90;
        &:hover {
          background: @tq-neutral-800;
        }
      }

      .cs-devider {
        max-width: 351px;
        margin: auto;

        @media @screen767 {
          max-width: 240px;
        }

        .ant-divider {
          border-block-start: rgba(0, 0, 0, 0.5);
          margin: 24px 0;

          @media @screen767 {
            margin: 20px 0;
          }

          .ant-divider-inner-text {
            .paragraph-s;
          }
        }
      }
    }
  }
}
