.mobile-view {
  display: table;
  width: 100%;
  height: calc(100vh - 60px);
  position: sticky;
  top: 60px;
  background: #a5a5a5;
  .mobile-wrapper {
    display: table-cell;
    vertical-align: middle;
  }
  .phone {
    max-width: 380px;
    width: 100%;
    height: 80vh;
    padding: 5px;
    background: black;
    border: 5px solid gray;
    border-radius: 50px;
    overflow: hidden;
    margin: auto;
    .screen {
      position: relative;
      width: 100%;
      height: 100%;
      border-radius: 40px;
      overflow: auto;
      background: var(--tq-ivory);
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
      .mb-header {
        background: var(--tq-neutral-1000);
        height: 60px;
        .text-white-90;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .responsive-view.video-info-section {
        padding-bottom: 40px;
        .video-info-wrapper {
          padding: 0 16px;
          .ant-avatar {
            border-radius: 50%;
          }
        }
        .videoaction {
          border-radius: 8px;
        }
      }
      .responsive-view.newsletter-section {
        .cs-button.ant-btn {
          border-radius: 8px;
        }
      }
      .mb-footer {
        background: var(--tq-footer-bg);
        height: 100px;
        .text-white-90;
        .text-white-90;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
