video::cue {
  line-height: 150px;
}

.player-wrapper {
  height: calc(100vh - 198px);
  background-color: @tq-neutral-1000;
  position: relative;
  overflow: hidden;
}

.player-wrapper {
  &:hover {
    .custom-control-wrap {
      opacity: 1;
      visibility: visible;
    }

    .custom-overlay-without-controls {
      opacity: 0;
      visibility: hidden;
    }
  }
}

.custom-control-wrap {
  opacity: 0;
  visibility: hidden;
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  .flex-vertical;
  z-index: 1;
  .d-flex;
  justify-content: space-between;
  padding: 16px 48px;
  transition: ease 0.25s;

  @media @screen1150 {
    padding: 24px;
  }

  @media @screen767 {
    padding: 16px;
  }

  .volume-section {
    .d-flex;
    .align-center;
    flex-direction: row;
    margin-right: 10px;

    @media @screen767 {
      display: none;
    }

    .ant-slider {
      width: 128px;
      height: 24px;
      margin: 0;
      padding: 8px 0;

      .ant-slider-rail {
        height: 6px;
        background: @tq-neutral-700;
        border-radius: 0;
      }

      .ant-slider-track {
        height: 6px;
      }

      .ant-slider-handle {
        height: 16px;
        width: 16px;
        inset-block-start: 3px;

        &:before {
          height: 16px;
          width: 16px;
        }

        &:after {
          height: 16px;
          width: 16px;
          box-shadow: none;
        }

        // &:hover {
        //   &:after {
        //     box-shadow: 0 0 0 2px #1a1714;
        //   }
        // }
      }
    }

    .ant-slider-track {
      background-color: @tq-ivory;
      border-radius: 0;
    }
  }

  .controlitem-middle {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    left: 0;

    .play-pause-btn {
      .d-flex;
      .align-center;
      .justify-center;
      color: #fff;
      gap: 24px;
    }
  }

  .play-duration {
    .d-flex;
    justify-content: flex-start;
    .align-center;
    font-size: 18px;
    color: #fff;
    gap: 4px;

    .text-md {
      @media @screen767 {
        font: @tq-text-tiny;
      }

      &:last-child {
        opacity: 0.6;
      }
    }
  }

  .duration-bar {
    padding-bottom: 6px;
  }
}

.custom-title {
  position: absolute;
  top: 20px;
  right: 0;
  left: 20px;
  z-index: 5;
  .d-flex;
  padding: 5px;
  color: #fff;
  font-size: large;
}

.show-title.custom-title {
  opacity: 1;
}

.hide-title.custom-title {
  opacity: 0;
}

.custom-overlay-without-controls {
  position: absolute;
  bottom: 16px;
  right: 0;
  left: 0;
  z-index: 2;
  .d-flex;
  justify-content: flex-end;
  padding: 0 45px;
  transition: ease 0.25s;

  @media @screen1150 {
    padding: 0 24px;
  }

  @media @screen767 {
    padding: 0 16px;
  }

  &.show {
    bottom: 65px;
  }
}

.controls-items {
  .d-flex;
  justify-content: space-between;
  padding-top: 12px;
  padding-bottom: 0;
}

.controls-items {
  .ant-select-arrow .anticon>svg {
    vertical-align: top;
    height: 12px;
    color: #d9d9d9;
  }
}

.left-controls {
  .d-flex;
  .align-center;
}

.left-controls svg {
  cursor: pointer;
  margin-right: 10px;
  color: #fff;

  @media @screen767 {
    width: 18px;
    height: auto;
  }
}

.right-controls {
  .d-flex;
}

.right-controls svg {
  cursor: pointer;
  margin-left: 10px;
  color: #fff;

  @media @screen767 {
    margin-left: 8px;
    width: 24px;
    height: auto;
  }
}

.controlitem-top {
  svg {
    @media @screen767 {

      width: 24px;
      height: auto;
    }
  }
}

.custom-overlay-with-controls {
  margin-bottom: 16px;

  @media @screen767 {
    display: none;
  }
}

.play-box {
  .d-flex;
  .align-center;
  justify-content: space-between;
  margin-right: 15px;

  @media @screen767 {
    margin-right: 8px;
  }
}

.playback-rate-selector {
  width: 100px;
}

.player-popover {
  .ant-popover-content {
    padding: 0;

    .ant-popover-inner-content {
      padding: 0;

      .ant-select-selector {
        border: 0;
      }
    }
  }
}

// .active-caption {
//   border-bottom: 2px solid #d90000;
// }

.chapters-wrapper {
  .d-flex;
  width: 100%;
  overflow-x: auto;
}

.chapters-item {
  margin: 5px 20px 0 0;
  padding: 10px;
  border: 1px solid grey;
  cursor: pointer;
}

.chapters-title {
  padding: 5px;
  color: #fff;
}

.active-chapters {
  background-color: rgb(48, 48, 48);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.158);
  color: white;
}

.playlist-item {
  width: 20%;
  margin: 10px;
}

.playlist-img {
  width: 100px;
  margin-right: 10px;
}

.share-item {
  border: 1px solid grey;
  border-radius: 10px;
  justify-content: space-between;
}

// new css

.hide-btn {
  opacity: 0;
  z-index: -1;
  content-visibility: 'hidden';
  padding: 0;
  margin: 0;
  outline: 'none';
  border: none;
  line-height: 0;
  appearance: none;
}

.back-forward-btn {
  appearance: none;
  background-color: transparent;
  border: 0;
  padding: 0;
  width: 40px;
}

.back-forward-btn svg {
  height: 40px;
  width: auto;
}

.back-forward-btn:focus,
.back-forward-btn:active,
.back-forward-btn:focus-within {
  border: none;
  box-shadow: none;
  outline: 0;
}

.progress-wrap {
  position: relative;
  width: 100%;
}

.progress-wrap input[type='range'] {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  border: 0;
  cursor: pointer;
  outline: none;
  z-index: 3;
  height: 6px;
}

.progress-wrap progress[value] {
  appearance: none;
  border: none;
  width: 100%;
  height: 6px;
  border-radius: 6px;
  overflow: hidden;
  /* for IE */
  color: black;
}

.progress-wrap::-webkit-progress-value {
  border-radius: 0 6px 6px 0;
}

.progress-wrap::-moz-progress-bar {
  border-radius: 0 6px 6px 0;
}

.progress-wrap progress#progress-played[value] {
  position: absolute;
  z-index: 2;
  color: #d90000;
}

.progress-wrap progress#progress-played[value]::-webkit-progress-bar {
  background-color: transparent;
}

.progress-wrap progress#progress-played[value]::-webkit-progress-value {
  background: #d90000;
}

.progress-wrap progress#progress-played[value]::-moz-progress-bar {
  background: #d90000;
}

.progress-wrap progress#progress-loaded[value] {
  position: absolute;
  z-index: 1;

  /* for IE */
  color: #b3b3b3;
}

.progress-wrap progress#progress-loaded[value]::-webkit-progress-bar {
  background-color: rgba(255, 255, 255, 0.35);
}

.progress-wrap progress#progress-loaded[value]::-webkit-progress-value {
  background: #fff;
}

.progress-wrap progress#progress-loaded[value]::-moz-progress-bar {
  background-color: rgba(255, 255, 255, 0.35);
}

.video-player-wrap {
  position: relative;
  padding-bottom: 56.25%;
  box-sizing: border-box;
}

.player {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 10;
}

video {
  width: 100%;
  height: 100%;
}

.control-overlay {
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
  height: 100%;
  width: 100%;
}

.control-wrap {
  .d-flex;
  .align-center;
  justify-content: space-between;
  width: 350px;
}

.video-player-wrap .control-wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.play-controls {
  // position: absolute;
  width: 100%;
  bottom: 0%;
  padding: 0px 24px;
  box-sizing: border-box;
}

.video-title {
  font-weight: bold;
  font-size: 24px;
  line-height: 150%;
  color: #ffffff;
  margin-bottom: 16px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.video-player-wrap.show-controls .control-wrap {
  opacity: 1;
}

.video-player-wrap.hide-controls .control-wrap {
  opacity: 0;
}

.video-player-wrap.show-controls .play-controls {
  opacity: 1;
}

.video-player-wrap.hide-controls .play-controls {
  opacity: 0;
}

.video-player-wrap.show-controls .control-overlay {
  opacity: 1;
}

.video-player-wrap.hide-controls .control-overlay {
  opacity: 0;
}

.control-wrap button:focus {
  border: none;
  box-shadow: none;
}

.control-wrap button:active {
  border: none;
  box-shadow: none;
}

.control-wrap button:focus-visible {
  outline: none;
}

.control-wrap button:focus-within {
  outline: none;
}

.dropdown {
  position: absolute;
  right: 30px;
  top: 85px;
  text-align: right;
}

.caption-wrap {
  position: relative;
  display: inline-block;
}

.caption-popover-wrap {
  position: absolute;
  bottom: 50px;
  right: 0px;
  background: rgba(0, 0, 0, 0.5);
  box-shadow: 0 29px 48px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  min-width: 80px;
  z-index: 1;
  border-radius: 8px;
  text-align: left;
  padding: 8px 10px;
}

.off-btn {
  background: transparent;
  border: 2px solid transparent;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  border-radius: 4px;
  width: 100%;
  text-align: left;
  padding: 5px;
}

.caption-popover-title {
  margin: 5px 0px;
}

.caption-popover-title:last-child {
  margin: 0px 0px;
}

.caption-popover-wrap .caption-popover-title button:focus {
  border: 2px solid #fff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.16);
}

.caption-popover-wrap .caption-popover-title button:active {
  border: 2px solid #fff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.16);
}

.caption-popover-wrap .caption-popover-title button:focus-within {
  outline: none;
}

.caption-popover-wrap .caption-popover-title button:focus-visible {
  outline: none;
}