.heavy-module {
  padding: 100px 0;

  @media @screen767 {
    padding: 60px 0;
  }

  &.heromodule {
    background-color: @tq-neutral-900;
    padding-top: 300px;
    position: relative;

    @media @screen767 {
      padding-top: 100px;
    }

    .cover-img {
      z-index: 1;
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;

      &::before {
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        z-index: 1;
        background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 36.75%,
            rgba(0, 0, 0, 0) 36.76%,
            rgba(0, 0, 0, 0.24) 66.73%,
            rgba(0, 0, 0, 0.85) 100%
          ),
          linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
      }

      img {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        object-fit: cover;
      }
    }

    .container {
      position: relative;
      z-index: 1;
    }

    .button-wrapper {
      justify-content: center;
    }

    .heavy-module-container {
      .cms-content {
        overflow: hidden;
        white-space: pre-wrap;
        .text-n-0;
      }

      h2 {
        .display-h0;
        .text-n-0;

        @media @screen767 {
          .display-h2;
        }
      }

      .contributor {
        .text-n-0;
      }

      .date {
        .heading-h5;
        .text-n-0;
      }
    }
  }

  .heavy-module-container {
    .container-marketing;

    h2 {
      .display-h2;
      .mb-24;

      @media @screen767 {
        .display-h4;
      }
    }

    .date {
      .heading-h4;
      opacity: 0.8;

      @media @screen767 {
        .heading-h7;
      }
    }

    .contributor {
      display: flex;
      align-items: center;
      gap: 12px;
      .mb-16;

      .ant-avatar {
        @media @screen767 {
          height: 40px;
          width: 40px;
        }
      }

      .contributor-content {
        .contributor-name {
          .heading-h5;

          @media @screen767 {
            .text-small-bold;
            .mb-8;
          }
        }

        p {
          .paragraph-m;

          @media @screen767 {
            .text-small;
          }
        }
      }
    }

    .cms-content {
      overflow: hidden;
      white-space: pre-wrap;
    }
  }

  .button-wrapper {
    display: flex;
    align-items: center;
    gap: 32px;
    margin-top: 40px;

    @media @screen767 {
      gap: 12px;
    }

    button {
      @media @screen767 {
        flex: 1;
      }
    }
  }
}
