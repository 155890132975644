.ctaBanner-section {
  padding: 80px 0;

  @media @screen767 {
    padding: 0;
  }

  .cta-bnnner-block {
    width: 100%;
    position: relative;

    &.ctabannerbackgroundcolor {
      padding: 40px 0;
      border-radius: 16px;

      @media @screen767 {
        padding: 60px 16px;
        border-radius: 0;
      }
    }

    &.ctabannerbackground-none {
      padding: 0;

      @media @screen767 {
        padding: 60px 16px;
      }
    }

    img {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      object-fit: cover;
    }

    .cta-content {
      position: relative;
      z-index: 1;
      max-width: 1000px;
      margin: auto;
      .text-center;

      .title {
        .display-h2;
        .mb-16;
      }

      .description {
        .paragraph-l-xl;
        margin-bottom: 40px;
      }
    }

    &.ctabannerbackground {
      padding: 80px 16px;
      border-radius: 16px;
      overflow: hidden;

      @media @screen767 {
        border-radius: 0;
        padding: 60px 16px;
      }

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background: radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.0504834) 70.17%, rgba(0, 0, 0, 0.5) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
        z-index: 1;
      }
    }
  }
}